<template>
    <div class="client-box">
         <!-- 面包屑 -->
        <el-breadcrumb separator="/">
            <el-breadcrumb-item>客户管理</el-breadcrumb-item>
            <el-breadcrumb-item>客户预约</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 顶部栏 -->
        <div class="header-bar">
            <el-form :inline="true" :model="form" class="demo-form-inline">
                <el-form-item v-if="editRule">
                    <el-button @click="ToAddApt" type="primary" size="small" icon="el-icon-plus">添加</el-button>
                </el-form-item>
                <el-form-item label="客户">
                        <el-autocomplete
                        class="inline-input"
                        v-model="form.client"
                        :fetch-suggestions="searchClient"
                        placeholder="搜索客户"
                        :trigger-on-focus="false"
                        @select="selectClient"
                        ></el-autocomplete>
                </el-form-item>
                <el-form-item label="所在区域">
                    <el-select v-model="form.region" placeholder="所在区域">
                    <el-option v-for="item in parameters.regions" :key="item.pid"
                    :label="item.desc" :value="item.desc"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item >
                <div class="time-box">
                   <el-date-picker
                        v-model="form.date"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                </div>
                </el-form-item>
                <el-form-item>
                    <el-button class="margin" @click="search" icon="el-icon-search" size="small">搜索</el-button>
                </el-form-item>
            </el-form>
        </div>
        <!-- list-client -->
        <div class="pro-con">
            <!-- table -->
            <el-table
            border
            v-loading="loading"
            :data="dataList"
            style="width: 100%">
                <el-table-column
                type="selection"
                width="55">
                </el-table-column>
                <el-table-column
                    prop="client"
                    width="150"
                    label="客户简称">
                </el-table-column>
                 <el-table-column
                    prop="nickName"
                    width="150"
                    label="预约人">
                </el-table-column>
                <el-table-column
                    prop="region"
                    width="120"
                    label="区域">
                </el-table-column>
                <el-table-column
                    prop="date"
                    width="120"
                    label="预约日期">
                </el-table-column>
                <el-table-column
                    prop="time"
                    width="120"
                    label="时间">
                </el-table-column>
                <el-table-column
                    prop="remark"
                    width="150"
                    label="文字备注">
                </el-table-column>
                <el-table-column
                    prop="msg"
                    width="150"
                    label="回复客户">
                </el-table-column>
                <el-table-column
                    prop="status"
                    width="150"
                    label="状态">
                    <template slot-scope="scope">
                        <el-dropdown>
                            <el-button :disabled="!editRule" size="mini" plain :type="scope.row.status|matchStatus">
                               {{scope.row.status|matchAppoint}}<i class="el-icon-arrow-down el-icon--right"></i>
                            </el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item v-for="item in [0,1,2,4]" :key="item" @click.native="changeSatus(scope.row.aid,item)">
                                {{item|matchAppoint}}</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>
                <el-table-column
                    v-if="editRule"
                    width="100"
                    fixed="right"
                    label="操作">
                    <template slot-scope="scope">
                        <i class="edit el-icon-delete-solid" @click="handleDelete(scope.row.aid)"></i>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- list-client-end -->
        <!-- 添加预约 -->
        <el-dialog title="添加预约" 
        :visible.sync="drawerAppoint">
            <AddApt @cancel="cancelAdd" @addSuc="addSuc"/>
        </el-dialog>
    </div>
</template>
<script>
import AddApt from '@/components/AddAppointment.vue';
import {formatTime,checkRules} from '@/utils/util.js'
export default {
    components:{
        AddApt
    },
    data(){
        return{
            editRule:checkRules(15),//更改权限
            drawerAppoint:false,//预约
            loading:false,
            dataList:[],
            form:{
                client:'',
                region:'',
                date:[]
            },
            parameters:{
                type:[],
                regions:[]
            },//客户的区域选项
        }
    },
    created(){
        this.getParameters()
        this.form.date=[
            new Date(( ( new Date().getTime()/1000 ) - ( 10 * 86400 ) ) * 1000),
            new Date()
        ]
        //初始化
        this.search()
    },
    methods:{
        //添加成功
        addSuc(){
            this.drawerAppoint = false
            this.search()
        },
        //取消添加预约
        cancelAdd(){
            this.drawerAppoint = false
        },
        //添加预约
        ToAddApt(){
            this.drawerAppoint = true
        },
        //删除
        handleDelete(e){
            //console.log(e)
            this.$confirm(this.CONST.DEL_CONFIRM, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    let params = {
                        method:'delAppointment',
                        aid:e
                    }
                    this.loading=true
                    //console.log(params)
                    this.$ajax.post(this.API.api,params).then(res=>{
                        this.loading = false
                        this.$message({
                            message: res.msg?res.msg:this.CONST.DEL_SUC,
                            type: 'warning'
                        });
                        //刷新
                        this.search()
                    }).catch(err=>{
                        this.loading = false
                        this.$message({
                            message: err.msg?err.msg:this.CONST.FAIL_TIME_OUT,
                            type: 'warning'
                        });
                    })
            })
        },
        //（不同意）同意预约
        changeSatus(aid,item){
            console.log(aid,item)
            let params = {
                        method:'updateAppointment',
                        aid,
                        status:item
                    }
                    this.loading=true
                    //console.log(params)
                    this.$ajax.post(this.API.api,params).then(res=>{
                        this.loading = false
                        this.$message({
                            message: res.msg?res.msg:this.CONST.UPDATE_SUC,
                            type: 'warning'
                        });
                        //刷新
                        this.search()
                    }).catch(err=>{
                        console.log(err)
                        this.loading = false
                        this.$message({
                            message: err.msg?err.msg:this.CONST.FAIL_TIME_OUT,
                            type: 'warning'
                        });
                    })
        },
        //弹出搜索客户
        searchClient(queryString, cb){
            var clients = this.clients;
            var results = queryString ? clients.filter(this.createFilter(queryString)) : clients;
            // 调用 callback 返回建议列表的数据
            cb(results);
        },
        createFilter(queryString) {
            return (clients) => {
            return (clients.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
            };
        },
        //获取预约列表
        getAppointment(){
            let params = this.form
            params.method = 'getAppointment'
            params.start = formatTime(this.form.date[0]).split(" ")[0]
            params.end = formatTime(this.form.date[1]).split(" ")[0]
            if(params.region!=''){
                //如果选择了区域则清空客户名
                params.client = ''
                this.$message({
                    message: this.CONST.ONLY_REGION,
                    type: 'warning'
                });
            }
            //console.log(params)
            this.loading = true
            this.$ajax.post(this.API.api,params).then(({data})=>{
                this.loading = false
                if(data){
                    this.dataList=data
                }
            }).catch(err=>{
                console.log(err.data)
                this.loading = false
                this.$message({
                    message: err.msg?err.msg:this.CONST.FAIL_TIME_OUT,
                    type: 'warning'
                });
            });
        },
        //搜索
        search(){
            this.dataList=[]
            this.getAppointment()
        },
        //添加
        add(){

        },
        //选择客户
        selectClient(){

        },
        //获取客户类型参数
        getParameters(){
            let params = {
                method:"getParameters"
            }
            let that = this
            this.$ajax.post(this.API.api,params).then(res=>{
                //todo
                //console.log(res)
                res.data.forEach(function(item){
                    if(item.pa_type==1){
                        that.parameters.type.push(item)
                    }
                    else if(item.pa_type==2){
                        that.parameters.regions.push(item)
                    }
                })
            }).catch(err=>{
                console.log(err)
                //err
                this.$message({
                    message: err.msg?err.msg:this.CONST.FAIL_TIME_OUT,
                    type: 'warning'
                });
            })
        }
    }
}
</script>
<template>
    <div class="box">
        <el-form v-loading="loadingV" ref="form" :model="form" label-width="80px" :rules="rules">
            <el-form-item label="客户名称" required prop="client">
                <el-autocomplete
                class="inline-input"
                v-model="form.client"
                :fetch-suggestions="getClientList"
                placeholder="请输入内容"
                :trigger-on-focus="false"
                @select="handleSelect"
                ></el-autocomplete>
            </el-form-item>
            <el-form-item label="预约时间" required prop="date_">
                <el-col :span="11">
                <el-date-picker type="date" placeholder="选择日期" v-model="form.date_" style="width: 100%;"></el-date-picker>
                </el-col>
                <el-col class="line" :span="2">-</el-col>
                <el-col :span="11">
                <el-time-picker placeholder="选择时间" v-model="form.time_" style="width: 100%;"></el-time-picker>
                </el-col>
            </el-form-item>
            <el-form-item label="备注" prop="remark">
                <el-input type="textarea" v-model="form.remark"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSubmit">立即创建</el-button>
                <el-button @click="cancel">取消</el-button>
            </el-form-item>

        </el-form>
    </div>
</template>
<script>
import {formatTime} from '@/utils/util.js'
export default {
    data(){
        return{
            loadingV:false,
            user:{},
            clientList:[],
            form:{
                client:"",
                remark:"",
                date_:new Date(),
                time_:new Date()
            },
            rules:{
                client: [
                    { required: true, message: '请输入客户名称', trigger: 'blur' }
                ],
                remark: [
                    { required: false, message: '请输入备注内容', trigger: 'blur' },
                    { min: 2, max: 100, message: '长度在 1 到 100 个字符', trigger: 'blur' }
                ]
            }
        }
    },
    created(){
        this.user = JSON.parse(sessionStorage.getItem('user'))
    },
    methods:{
        //提交
        onSubmit(){
            this.$refs['form'].validate((valid) => {
                //console.log(valid)
                if(valid){
                    this.form.date = formatTime(this.form.date_).split(" ")[0] 
                    if(this.form.time_){
                        let timeStr = formatTime(this.form.time_).split(" ")[1]
                        this.form.time=timeStr.split(":")[0]+":"+timeStr.split(":")[1]
                    }
                    if(!this.form.cid){
                        //未获取到客户的cid
                        this.$message({
                            message: this.CONST.CLIENT_NOT_EXIT,
                            type: 'warning'
                        });
                    }
                    this.form.nickName = this.user.username
                    this.form.method = "addAppointment"
                    this.loadingV = true
                    this.$ajax.post(this.API.api,this.form).then(({msg})=>{
                        this.loadingV = false
                        this.$message({
                            message: msg||this.CONST.SUC_ADD,
                            type:'success'
                        })
                        this.form.client = ""
                        this.$emit('addSuc')
                    }).catch(err=>{
                        console.log(err)
                        this.loadingV = false
                        this.$message({
                            message: err.msg?err.msg:this.CONST.FAIL_TIME_OUT,
                            type: 'warning'
                        });
                    })
                }
            })
            
        },
        //取消
        cancel(){
            this.$emit('cancel')
        },
        //搜索客户
        getClientList(queryString, cb){
            let that=this
                let params = {
                    method:'getClientList',
                    page:1,
                    pageSize:5,
                    client:this.form.client
                }
                //console.log(this.form)
                that.$ajax.post(that.API.api,params).then(res=>{
                    //todo
                    //that.loading = false
                    let list = [] 
                    if(res.data.list){
                        res.data.list.forEach(function(item){
                            list.push({
                                value:item.shortname,
                                cid:item.cid
                            })
                        })
                    }
                    that.clientList = list 
                    that.querySearch(queryString, cb)
                }).catch(err=>{
                    //err
                    console.log(err)
                    that.$message({
                        message: err.msg?err.msg:that.CONST.FAIL_TIME_OUT,
                        type: 'warning'
                    });
                })
        },
        //选择客户
        handleSelect(e){
            console.log(e)
            if(e.cid){
                this.form.cid = e.cid
            }else{
                this.$message({
                    message: this.CONST.CLIENT_NOT_EXIT,
                    type: 'warning'
                });
            }
        },
        querySearch(queryString, cb) {
            var results = queryString ? this.clientList.filter(this.createFilter(queryString)) : this.clientList;
            // 调用 callback 返回建议列表的数据
            //console.log(results)
            cb(results);
           
        },
        createFilter(queryString) {
            return (clientList) => {
            //console.log(clientList,queryString)
            //console.log(clientList.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
            return (clientList.value.toLowerCase().indexOf(queryString.toLowerCase()) != -1);
            };
        }
    }
}
</script>